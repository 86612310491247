import api from './index';

class ExpenseService {
    async find(startDate, endDate, isToMoveAccount) {
        return api.get(`/expenses`, {
            params: {
                startDate: startDate,
                endDate: endDate,
                isToMoveAccount: isToMoveAccount,
            },
        });
    }

    async create(data) {
        return api.post(`/expenses`, data);
    }

    async update(data) {
        return api.put(`/expenses`, data);
    }

    async delete(id) {
        return api.delete(`/expenses/${id}`);
    }

    async deleteMany(selectedCategories) {
        return api.post(`/expenses/deleteMany`, selectedCategories);
    }
}

export default new ExpenseService();