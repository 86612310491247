<template>
    <div class='grid crud-demo'>
        <div class='col-12'>
            <div class='card'>
                <Toast />
                <Toolbar class='mb-4'>
                    <template v-slot:end>
                        <div class='my-2'>
                            <Button label='Adicionar' icon='pi pi-plus' class='p-button-success mr-2'
                                    @click='openNew' />
                            <Button label='Deletar' icon='pi pi-trash' class='p-button-danger'
                                    @click='confirmDeleteSelected'
                                    :disabled='!selectedExpenses || !selectedExpenses.length' />
                        </div>
                    </template>
                </Toolbar>
                <div class='grid p-fluid'>
                    <div class='col-12 md:col-6 xl:col-3' v-for='(value, category) in expensesByCategory'
                         :key='category'>
                        <div class='card grid-nogutter widget-overview-box widget-overview-box-1'>
                    <span class='overview-icon'>
                        <i class='pi pi-shopping-cart'></i>
                    </span>
                            <span class='overview-title'> {{ category }}</span>
                            <div class='grid overview-detail'>
                                <div class='col-12'>
                                    <div class='overview-number'>{{ formatCurrency(value) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable ref='dt' :value='expenses' v-model:selection='selectedExpenses' dataKey='id'
                           :paginator='true' :rows='10' :filters='filters'
                           paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                           :rowsPerPageOptions='[5,10,25]'
                           currentPageReportTemplate='Mostrando {first} ~ {last} total {totalRecords} despesas'
                           responsiveLayout='scroll'>
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='m-0'>Despesas</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Pesquisar...' />
                            </span>
                        </div>
                        <div class='flex flex-wrap md:flex-nowrap mt-2'>
                            <div>
                                <Calendar placeholder='Data de incio' date-format='yy-mm-dd' :showIcon='true'
                                          :showButtonBar='true' v-model='startDate'></Calendar>
                            </div>
                            <div class='ml-3'>
                                <Calendar placeholder='Data de fim' date-format='yy-mm-dd' :showIcon='true'
                                          :showButtonBar='true' v-model='endDate'></Calendar>
                            </div>
                            <div class='ml-3'>
                                <Checkbox id='bankIsToMoveAccount' class='mt-2' v-model='isToMoveAccount'
                                          :binary='true'></Checkbox>
                                <label for='bankIsToMoveAccount' class='ml-2'>Descontados da conta do Banco</label>
                            </div>
                            <div class='flex md:ml-auto md:items-center mt-3 md:mt-0'>
                                <Button label='Filtrar' icon='pi pi-filter' class='p-button-info mr-2'
                                        @click='fetchExpenses' />
                            </div>
                        </div>

                    </template>

                    <Column selectionMode='multiple' headerStyle='width: 3rem'></Column>
                    <Column headerStyle='width: 20rem' field='name' header='Nome' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='amount' header='Valor' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ formatCurrency(slotProps.data.amount) }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='date' header='Data'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Data</span>
                            {{ slotProps.data.date }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='category' header='Categoria'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Categoria</span>
                            {{ slotProps.data.category.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 20rem' field='bank' header='Banco' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Banco</span>
                            {{ slotProps.data.bank.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 8rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-success mr-2'
                                    @click='editExpense(slotProps.data)' />
                            <Button icon='pi pi-trash' class='p-button-rounded p-button-warning'
                                    @click='confirmDeleteExpense(slotProps.data)' />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible='expenseDialog' :style="{width: '450px'}" header='Criar / Editar Despesa'
                        :modal='true' class='p-fluid'>
                    <div class='field'>
                        <label for='name'>Nome*</label>
                        <InputText id='name' v-model.trim='expense.name' required='true' autofocus
                                   :class="{'p-invalid': v$.expense.name.$invalid && submitted}" />
                        <small
                            v-if='(v$.expense.name.$invalid && submitted) || v$.expense.name.$pending.$response'
                            class='p-error'>{{ v$.expense.name.required.$message.replace('Value', 'Nome') }}</small>
                    </div>

                    <div class='field'>
                        <label for='amount'>Valor*</label>
                        <InputNumber id='amount' mode='currency' currency='JPY' locale='ja-JP'
                                     v-model.trim='expense.amount' required='true' autofocus
                                     :class="{'p-invalid': v$.expense.amount.$invalid && submitted}" />
                        <small
                            v-if='(v$.expense.amount.$invalid && submitted) || v$.expense.amount.$pending.$response'
                            class='p-error'>{{ v$.expense.amount.required.$message.replace('Value', 'Valor') }}</small>
                    </div>

                    <div class='field'>
                        <label for='date'>Data*</label>
                        <Calendar dateFormat='yy-mm-dd' id='date' v-model.trim='expense.date' required='true' autofocus
                                  :class="{'p-invalid': v$.expense.date.$invalid && submitted}" />
                        <small
                            v-if='(v$.expense.date.$invalid && submitted) || v$.expense.date.$pending.$response'
                            class='p-error'>{{ v$.expense.date.required.$message.replace('Value', 'Data') }}</small>
                    </div>


                    <div class='field'>
                        <label for='description'>Descrição</label>
                        <Textarea :maxlength='200' id='description' v-model.trim='expense.description' autofocus />
                    </div>

                    <div class='field'>
                        <label for='category'>Categoria*</label>
                        <Dropdown :filter='true' :class="{'p-invalid': v$.expense.categoryId.$invalid && submitted}"
                                  :options='categories' id='category' v-model='expense.categoryId' optionLabel='label'
                                  placeholder='Seleciona uma categoria'>
                            <template #value='slotProps'>
                                <div v-if='slotProps.value && slotProps.value.value'>
                                    <span>{{ slotProps.value.label }}</span>
                                </div>
                                <span v-else>
									{{ slotProps.placeholder }}
								</span>
                            </template>
                        </Dropdown>
                        <small
                            v-if='(v$.expense.categoryId.$invalid && submitted) || v$.expense.categoryId.$pending.$response'
                            class='p-error'>{{ v$.expense.categoryId.required.$message.replace('Value', 'Categoria')
                            }}</small>
                    </div>

                    <div class='field-checkbox'>
                        <Checkbox id='isToMoveAccount' v-model='expense.isToMoveAccount' :binary='true' />
                        <label for='isToMoveAccount'>Descontar do saldo da conta do banco ?</label>
                    </div>

                    <div class='field' v-if='expense.isToMoveAccount'>
                        <label for='category'>Banco*</label>
                        <Dropdown :filter='true' :class="{'p-invalid': v$.expense.bankId.$invalid && submitted}"
                                  :options='banks' id='category' v-model='expense.bankId' optionLabel='label'
                                  placeholder='Selecione um banco'>
                            <template #value='slotProps'>
                                <div v-if='slotProps.value && slotProps.value.value'>
                                    <span>{{ slotProps.value.label }}</span>
                                </div>
                                <span v-else>
									{{ slotProps.placeholder }}
								</span>
                            </template>
                        </Dropdown>
                        <small
                            v-if='(v$.expense.bankId.$invalid && submitted) || v$.expense.bankId.$pending.$response'
                            class='p-error'>{{ v$.expense.bankId.required.$message.replace('Value', 'Banco')
                            }}</small>
                    </div>


                    <template #footer>
                        <Button label='Cancelar' icon='pi pi-times' class='p-button-text' @click='hideDialog' />
                        <Button label='Salvar' icon='pi pi-check' class='p-button-text'
                                @click='saveExpense(!v$.$invalid)' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteExpenseDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='expense'>Tem certeza que deseja excluir a despesa <b>{{ expense.name
                            }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteExpenseDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text' @click='deleteExpense' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteExpensesDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='expense'>Tem certeza que deseja excluir as despesas selecionadas ?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteExpensesDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text'
                                @click='deleteSelectedExpenses' />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import ExpenseService from '@/services/expense.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import BankService from '@/services/bank.service';
import CategoryService from '@/services/category.service';
import moment from 'moment';

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            expenses: null,
            expenseDialog: false,
            deleteExpenseDialog: false,
            deleteExpensesDialog: false,
            expense: {
                isToMoveAccount: false,
            },
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),
            isToMoveAccount: false,
            selectedExpenses: null,
            filters: {},
            banks: {},
            categories: {},
            submitted: false,
            expensesByCategory: {},
        };
    },
    validations: {
        expense: {
            name: {
                required,
            },
            amount: {
                required,
            },
            date: {
                required,
            },
            categoryId: {
                required,
            },
            bankId: {
                required,
            },
        },
    },
    expenseService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.fetchExpenses();
        this.fetchApiForSelectBox();
    },
    methods: {
        formatCurrency(value) {
            //TODO use database to set this.
            if (value) return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        openNew() {
            this.expense = {};
            this.submitted = false;
            this.expenseDialog = true;
        },
        hideDialog() {
            this.expenseDialog = false;
            this.submitted = false;
        },
        async saveExpense(isFormValid) {
            if ((!isFormValid && this.expense.isToMoveAccount)) {
                this.submitted = true;
                return;
            }
            this.expense.categoryId = this.expense.categoryId.value;
            this.expense.bankId = this.expense.isToMoveAccount ? this.expense.bankId.value : null;
            this.expense.date = moment(this.expense.date).format('YYYY-MM-DD');
            if (this.expense.name.trim()) {
                if (this.expense.id) {
                    try {
                        let resp = await ExpenseService.update(this.expense);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                } else {
                    try {
                        let resp = await ExpenseService.create(this.expense);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                }
            }
            await this.fetchExpenses();
            this.expenseDialog = false;
            this.expense = {};
        },
        editExpense(expense) {
            this.expense = { ...expense };
            //set selected value on Dropdown
            this.expense.categoryId = this.categories.filter((item) => item.value === this.expense.category.id)[0];
            this.expense.bankId = this.banks.filter((item) => item.value === this.expense.bank.id)[0];

            this.expenseDialog = true;
        },
        confirmDeleteExpense(expense) {
            this.expense = expense;
            this.deleteExpenseDialog = true;
        },
        async deleteExpense() {
            try {
                let resp = await ExpenseService.delete(this.expense.id);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchExpenses();
            this.deleteExpenseDialog = false;
            this.expense = {};
        },
        confirmDeleteSelected() {
            this.deleteExpensesDialog = true;
        },
        async deleteSelectedExpenses() {
            try {
                let resp = await ExpenseService.deleteMany(this.selectedExpenses);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchExpenses();
            this.deleteExpensesDialog = false;
            this.selectedExpenses = null;
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async fetchExpenses() {
            let resp = await ExpenseService.find(this.startDate, this.endDate, this.isToMoveAccount);
            this.expenses = resp.data;
            await this.createChatData(resp.data);
        },
        async fetchApiForSelectBox() {
            let bankResp = await BankService.find();
            if (bankResp.data) {
                this.banks = bankResp.data.map(bank => {
                    return { label: bank.name, value: bank.id };
                });
            }

            let catResp = await CategoryService.find();
            if (catResp.data) {
                this.categories = catResp.data.filter(item => item.expense).map(cat => {
                    return { label: cat.name, value: cat.id };
                });
            }
        },
        createChatData(expenses) {
            const data = {};

            expenses.map(item => {
                const categoryName = item.category.name;
                const amount = item.amount;

                if (data[categoryName]) {
                    data[categoryName] += amount;
                } else {
                    data[categoryName] = amount;
                }
            });

            this.expensesByCategory = data;
        },
    },
};
</script>

<style scoped lang='scss'>

</style>
